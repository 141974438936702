/** @jsxImportSource theme-ui */

import {useEffect, useState} from 'react';

import {Icon} from '@iconify/react';
import Link from 'next/link';
import {useRouter} from 'next/router';
import {Flex, Grid} from 'theme-ui';

import useCurrentUser from 'hooks/data/useCurrentUser';

import {LoginButton, login} from './LoginButton';
import {SSOLogin} from './SSOLogin';

interface Props {
  isSignup?: boolean;
  isSSOLogin?: boolean;
  ssoIdentifier?: string;
}

const UserLogin = ({isSignup = false, isSSOLogin = false, ssoIdentifier}: Props) => {
  const router = useRouter();
  const [isSSO, setIsSSO] = useState(isSSOLogin);

  const {
    data: {user},
  } = useCurrentUser();

  useEffect(() => {
    if (user?.id != null) {
      router.push('/');
    }
  }, [user, router]);

  useEffect(() => {
    if (isSSOLogin) {
      setIsSSO(true);
    }
  }, [isSSOLogin]);

  if (isSSO) {
    return <SSOLogin ssoIdentifier={ssoIdentifier} onAllLoginOptions={() => setIsSSO(false)} />;
  }

  return (
    <Flex className="h-full flex-col items-center justify-between pb-8">
      <div>
        <Flex sx={{flexDirection: 'column'}} className="w-fit items-center gap-8 p-8">
          <h1 className="text-2xl">
            {isSignup ? 'Get started with Polar Signals' : 'Log in to Polar Signals'}
          </h1>
          {isSignup ? (
            <div className="flex flex-col items-center">
              <p className="flex items-center gap-4 text-center">
                <Icon icon="charm:circle-tick" /> No credit card required
              </p>
              <p className="max-w-sm text-center text-sm text-gray-500">
                Get a 14 day free trial and explore the features to your heart's content.
              </p>
              <div className="min-h-1 w-full border-b border-gray-200 pt-6 dark:border-gray-800" />
            </div>
          ) : null}
          <Grid className="w-80 grid-rows-2 gap-6">
            <LoginButton
              onClick={() => login('google', router.query)}
              icon={<Icon icon="logos:google-icon" height={20} />}
              text={isSignup ? 'Sign up with Google' : 'Sign in with Google'}
              textColor="text-[#1F1F1F] dark:[#E3E3E3]"
              background="bg-white dark:bg-[#131314]"
              iconBackground="bg-white dark:bg-[#131314]"
              title={isSignup ? 'Sign up with Google' : 'Sign in with Google'}
            />
            <LoginButton
              onClick={() => login('github', router.query)}
              icon={<Icon icon="ion:logo-github" height={22} color="white" />}
              text={isSignup ? 'Sign up with GitHub' : 'Sign in with GitHub'}
              textColor="text-white dark:text-white"
              background="bg-[#24292e] dark:bg-[#24292e]"
              title={isSignup ? 'Sign up with GitHub' : 'Sign in with GitHub'}
            />
            <LoginButton
              onClick={() => setIsSSO(true)}
              icon={<Icon icon="carbon:network-enterprise" height={22} color="text" />}
              text="Sign in with SSO"
              textColor="text"
              background="#fff"
              iconBackground="white"
              title="Sign in with SSO"
            />
          </Grid>
          {!isSignup ? (
            <p className="text-sm">
              New user?{' '}
              <Link href={'/signup'} legacyBehavior>
                <span className="cursor-pointer underline">Start your 14 day free trial!</span>
              </Link>
            </p>
          ) : (
            <p className="text-sm">
              Already have an account?{' '}
              <Link href={'/'} legacyBehavior>
                <span className="cursor-pointer underline">Log in</span>
              </Link>
            </p>
          )}
        </Flex>
      </div>
      {isSignup ? (
        <p className="max-w-sm text-center text-xs text-gray-500">
          By continuing, you agree to Polar Signals'{' '}
          <a
            href="https://www.polarsignals.com/tos"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Terms of Service
          </a>{' '}
          and{' '}
          <a
            href="https://www.polarsignals.com/privacy"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Privacy Policy
          </a>
          , and to receive periodic emails with updates.
        </p>
      ) : null}
    </Flex>
  );
};

export default UserLogin;
