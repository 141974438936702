import React, {useEffect} from 'react';

import {Spinner} from '@polarsignals/components';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {Analytics} from '@vercel/analytics/react';
import type {AppProps} from 'next/app';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {Toaster} from 'react-hot-toast';
import {Provider} from 'react-redux';
import {ThemeProvider} from 'theme-ui';

import CookieConsentBanner from 'components/CookieConsentBanner';
import {StaleDeploymentPrompt} from 'components/StaleDeploymentPrompt';
import UserLogin from 'components/UserLogin';
import {GrpcContextProvider} from 'contexts/GrpcContext';
import useCurrentUser from 'hooks/data/useCurrentUser';
import {AdminLayout} from 'layouts/Admin';
import ColorModeProvider from 'layouts/ColorModeProvider';
import ExternalScripts from 'layouts/ExternalScripts';
import LayoutLoggedIn from 'layouts/LoggedIn';
import type {LayoutProps} from 'layouts/LoggedIn';
import LayoutLoggedOut from 'layouts/LoggedOut';
import NoSidebarLayout from 'layouts/NoSidebar';
import store from 'store';
import theme from 'styles/theme';
import {initIconify} from 'utils/icons';

import 'styles/globals.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: (failureCount, _) => {
        if (failureCount < 1) {
          return true;
        }
        return false;
      },
    },
  },
});

initIconify();

const loggedOutLayoutPaths: Record<string, boolean> = {
  '/user-invite/[tokenId]': true,
  '/login': true,
  '/login/sso': true,
  '/signup': true,
};
const pathnameToLayoutMap: Record<string, React.FC> = {
  '/user-invite/[tokenId]': LayoutLoggedOut,
  '/request-access': NoSidebarLayout,
  '/getting-started': NoSidebarLayout,
  '/projects/[project-id]/[onboarding]': NoSidebarLayout,
  '/': NoSidebarLayout,
  '/admin': AdminLayout,
  '/admin/user-flags': AdminLayout,
  '/admin/org-flags': AdminLayout,
  '/admin/org-usage': AdminLayout,
  '/login/sso': LayoutLoggedOut,
  '/organizations/[organizationId]': NoSidebarLayout,
  '/organizations/[organizationId]/settings': NoSidebarLayout,
  '/user-profile': NoSidebarLayout,
  '/signup': LayoutLoggedOut,
};

const getLayoutForPathname: (pathname: string) => React.FC<LayoutProps> = (pathname: string) => {
  return pathnameToLayoutMap[pathname] ?? LayoutLoggedIn;
};

function App({Component, pageProps}: AppProps) {
  const {
    data: {user, loginConfig},
    loading,
    error,
  } = useCurrentUser();
  const {pathname} = useRouter();

  useEffect(() => {
    if (!user?.email || typeof window === 'undefined') {
      return;
    }

    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    try {
      (window as any).heap.identify(user.email);
      (window as any).heap.addUserProperties({name: user.name, company: user.company});
    } catch (error) {
      console.error(error);
    }
  }, [user?.email, user?.name, user?.company]);

  if (loading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <Spinner size={100} />
      </div>
    );
  }

  if (error) {
    console.error('Error while checking login status', error);
  }

  if (!user && !loggedOutLayoutPaths[pathname]) {
    return (
      <LayoutLoggedOut>
        <UserLogin
          isSSOLogin={loginConfig?.isSsoEnabled}
          ssoIdentifier={loginConfig?.ssoIdentifier}
        />
      </LayoutLoggedOut>
    );
  }

  const Layout = getLayoutForPathname(pathname);

  return (
    <Layout>
      <Component {...pageProps} />
    </Layout>
  );
}

const AppWithProviders = (props: AppProps) => {
  const {store: reduxStore} = store();

  return (
    <Provider store={reduxStore}>
      <QueryClientProvider client={queryClient}>
        <GrpcContextProvider>
          <ThemeProvider theme={theme}>
            <ColorModeProvider>
              <Head>
                <title>Polar Signals</title>
                <link rel="icon" href="/favicon.svg" media="(prefers-color-scheme: light)" />
                <link rel="icon" href="/favicon-dark.svg" media="(prefers-color-scheme: dark)" />
              </Head>
              <ExternalScripts />
              <App {...props} />
              <Toaster position="top-right" />
            </ColorModeProvider>
            <Analytics />
            <StaleDeploymentPrompt />
            <CookieConsentBanner />
          </ThemeProvider>
        </GrpcContextProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppWithProviders;
